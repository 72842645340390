/// <reference path="./embed.d.ts" />
declare const dataLayer: object[];
const direqt_local = import.meta.env.VITE_DIREQT_LOCAL === 'true';
const direqt_domain = !direqt_local
    ? 'https://chat.direqt.ai'
    : 'http://localhost:3000';

let direqt_instance: DireqtInstance;

const checkMobile = () => {
    const userAgent = navigator?.userAgent.toLowerCase();
    const isMobile =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
            userAgent
        );
    return isMobile;
};

const checkOrientationAndHideBot = () => {
    const orientation = window.screen.orientation.type;
    if (
        orientation.startsWith('landscape') &&
        checkMobile() &&
        direqt_instance.layout === 'overlay'
    ) {
        direqt_instance.parent.style.display = 'none';
    } else {
        direqt_instance.parent.style.display = 'block';
    }
};

const direqt_minHeight = 114;
const direqt_maxHeight = 500;
const direqt_isConsole = direqt_local
    ? window.location.host === 'localhost:4200'
    : window.location.host === 'console.direqt.io';
let direqt_listenersSet = false;

const direqt_layouts: DireqtStyles = {
    expand: {
        public: true,
    },
    hidden: {
        public: true,
        disableHeightChange: true,
        margin: '0',
        width: '0px',
        height: `0px`,
        display: 'none',
    },
    fixed: {
        public: true,
        disableHeightChange: true,
        height: `${direqt_maxHeight}px`,
    },
    faq: {
        public: true,
        height: `375px`,
    },
    //temporary modal used for quiz and share
    //shifts to different layout after use
    tempModal: {
        public: false,
        disableHeightChange: true,
        position: 'fixed',
        inset: '0',
        margin: 'auto',
    },
    modal: {
        public: true,
        disableHeightChange: true,
        position: 'fixed',
        inset: '0',
        margin: 'auto',
        width: '95vw',
        maxWidth: '800px',
        height: '85vh',
        display: 'none',
    },
    overlay: {
        public: true,
        disableHeightChange: true,
        margin: '0',
        borderRadius: '7.5px 7.5px 0 0',
        height: `124px`,
        position: 'fixed',
        inset: 'auto 36px 0px auto',
        width: '204px',
        zIndex: '9999999128',
        lineHeight: '0',
    },
    overlayOpen: {
        public: false,
        width: '400px',
        height: '600px',
    },
} as const;

const domainSpecificOptions: Record<string, DomainSpecificOptions> = {
    'direqttest.fake': {
        botId: 'fake-id',
        searchTrigger: {
            extraStyles: 'background-color: red; padding-left: 42px',
        },
    },
    'frontofficesportsstaging.com': {
        botId: '66d37b8bffb41cbe48d86a6b',
        searchTrigger: {
            extraStyles: 'padding-left: 15px; margin-bottom: 6px',
        },
        searchOnly: true,
    },
    'frontofficesports.com': {
        botId: '66c39bc97b24ebd4700485cc',
        searchTrigger: {
            extraStyles: 'padding-left: 15px; margin-bottom: 6px',
        },
        searchOnly: true,
    },
};

/***********
 * Functions related to loading the iframe
 ***********/

//do not rename without changing documentation that tells people they can call this manually
function initDireqt() {
    let direqt_el = document.querySelector(
        'ins.direqt-embed'
    ) as HTMLElement | null;

    //
    // Initialize the search bar if the decorator is present.
    //
    // Normally, we will attach the search bar to an existing embed element.
    // For registered search hosts, we will create the embed automatically if it
    // doesn't already exist.
    //
    if (document.querySelector('.direqt-search-input')) {
        const botId = domainSpecificOptions[window.location.hostname]?.botId;
        const searchOnly =
            domainSpecificOptions[window.location.hostname]?.searchOnly ||
            false;

        // If the direqt embed is not present
        if (!direqt_el && botId) {
            // create the direqt embed element
            direqt_el = document.createElement('ins');
            direqt_el.classList.add('direqt-embed');

            direqt_el.setAttribute('data-bot-id', botId as string);
            direqt_el.setAttribute('data-story-id', 'auto');
            direqt_el.setAttribute('data-layout', 'modal');
            direqt_el.setAttribute('data-strict', 'false');
            if (searchOnly) {
                direqt_el.setAttribute('data-search-only', 'true');
            }
            document.querySelector('body')?.appendChild(direqt_el);
        }

        if (!direqt_el) {
            console.warn(
                '[DIREQT] Direqt search unavailable (embedded chat not present).'
            );
            return;
        }

        searchBarInit(direqt_el);
    }

    if (!direqt_el) {
        console.error('[DIREQT] No Direqt embed container found');
        return;
    }

    const botId = direqt_el.getAttribute('data-bot-id');
    if (!botId) {
        console.error('[DIREQT] No botId found on Direqt embed container');
        return;
    }

    // Refuse to reinitiate iframe if it already exists or has been removed
    if (direqt_el.querySelector('iframe')) return;
    if (direqt_el.getAttribute('data-removed')) return;

    // Move overlay to bottom of body if applicable
    if (direqt_el.dataset.layout === 'overlay') {
        direqt_el.remove();
        document.querySelector('body')?.append(direqt_el);
    }

    direqt_instance = createInstance(direqt_el);
    populateIframe(direqt_el);
    direqt_setListeners();
}

function direqt_focusChat() {
    if (!direqt_instance) {
        return console.error('[DIREQT] No direqt instance found');
    }

    switch (direqt_instance.layout) {
        case 'hidden':
            break;
        case 'modal':
            setModalParentStyling();
            direqt_instance.iframe.style.display = 'initial';
            break;
        case 'overlay':
            direqt_instance.iframe.contentWindow!.postMessage(
                { cmd: 'direqt:openOverlay' },
                direqt_local ? '*' : 'https://chat.direqt.ai'
            );
            break;
        default:
            direqt_instance.iframe.scrollIntoView();
            break;
    }
}

function direqt_setListeners() {
    if (direqt_listenersSet) return; //only set listeners once
    function handleVisibilityChange() {
        const visibleEvent =
            document.visibilityState === 'hidden'
                ? 'direqt_windowHidden'
                : 'direqt_windowVisible';
        sendEvent(visibleEvent);
    }
    window.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('orientationchange', checkOrientationAndHideBot);
    direqt_listenersSet = true;
}

function generateUniqueId() {
    const timestamp = Date.now().toString();
    const random = Math.floor(Math.random() * 100000).toString();
    return timestamp + random;
}

function checkIsNumber(prop: unknown) {
    if (typeof prop !== 'string') {
        return false;
    }
    return !isNaN(parseFloat(prop));
}

function createInstance(direqt_el: HTMLElement): DireqtInstance {
    const elDataset = { ...direqt_el.dataset };
    const layout = (elDataset.layout || 'embed') as Layout;
    const isPublic = direqt_layouts[layout] && direqt_layouts[layout].public;
    return {
        botId: elDataset.botId || '',
        storyId: elDataset.storyId || '',
        layout: isPublic ? layout : 'expand',
        disableUiExtensions: elDataset.disableUiExtensions === 'true' || false,
        searchOnly: elDataset.searchOnly === 'true' || false,
        bottomPadding: checkIsNumber(elDataset.bottomPadding)
            ? parseFloat(elDataset.bottomPadding as string)
            : 0,
        placement: elDataset.placement || '',
        color: elDataset.color || '',
        strict: elDataset.strict || '',
        startHint: elDataset.startHint || '',
        collectAnalytics: direqt_el.dataset.collectAnalytics !== '0',
        gtm: elDataset.gtm || null,
        gtmDebug: checkDebugParam(),
        parent: direqt_el,
        iframe: document.createElement('iframe') as HTMLIFrameElement,
        messageListener: () => {}, //set in populateIframe()
        observer: {} as DireqtObserver, //set in populateIframe()
        params: {}, //set in getSrc()
        status: 'loading',
    };
}

function populateIframe(direqt_el: HTMLElement) {
    const isModal = getModalType();
    const useModalLayout = isModal !== 'none';
    if (useModalLayout) direqt_instance.layout = 'tempModal';

    const iframe = direqt_instance.iframe as HTMLIFrameElement;
    iframe.classList.add('direqt-iframe');
    iframe.title = 'Direqt Chatbot';
    iframe.allow = getPermissions();
    iframe.src = getSrc(isModal);
    setDefaultStyling(true);
    direqt_el.innerHTML = '';
    direqt_el.appendChild(iframe);

    direqt_instance.messageListener = event => handleMessage(event);
    window.addEventListener('message', direqt_instance.messageListener);
    direqt_instance.observer = createObserver();

    if (!useModalLayout) return;
    setModalParentStyling();
    openModal(isModal);
}

//sends 'direqt_seen' event when iframe is in view classSelector is used to
//target a specific custom element for the observer, leave blank for iframe
function createObserver(selector?: string): DireqtObserver {
    const el = selector
        ? document.querySelector(selector)
        : direqt_instance.iframe;
    let isVisible = false;
    let hasFired = false; //hasFired is used to prevent the event from being sent multiple times

    const finalizeObservation = () => {
        if (!el) return;
        observer.unobserve(el);
        hasFired = true;
    };

    //checks if iframe is in view and has loaded
    function checkStatus() {
        const { status } = direqt_instance;
        if (!selector && status === 'hidden') return finalizeObservation(); //no need to observe hidden chat
        if (!isVisible || hasFired || status !== 'loaded') return; //only send event if iframe is in view, has loaded, and hasn't fired
        sendEvent('direqt_seen', undefined, selector);
        finalizeObservation();
    }

    const inView = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
            isVisible = entry.isIntersecting;
            checkStatus();
        });
    };

    const observer = new IntersectionObserver(inView, {
        threshold: 0.9,
        trackVisibility: true,
        delay: 100,
    } as CustomIntersectionObserverInit);

    if (el) observer.observe(el);
    return { observer, checkStatus };
}

function getPermissions() {
    const permissions: string[] = [];

    // Check if the Web Share API is available
    const share = navigator.share as typeof navigator.share | undefined;
    const dummyShareObject = { title: 'test', text: 'test', url: 'url' };
    if (share && navigator.canShare(dummyShareObject)) {
        permissions.push('web-share');
    }

    // Check if Clipboard API permissions are available
    const clipboard = navigator.clipboard as
        | typeof navigator.clipboard
        | undefined;
    if (clipboard?.readText) permissions.push('clipboard-read');
    if (clipboard?.writeText) permissions.push('clipboard-write');

    return permissions.join('; ');
}

//searches for conditions that should force chat to be a modal layout
function getModalType(): 'none' | 'quiz' | 'daily-quiz' | 'share' {
    const params = new URLSearchParams(location.search);
    const quizParam = params.get('direqt_quiz_content');
    if (quizParam && isJSON(quizParam)) {
        const isDailyQuiz = JSON.parse(quizParam).isDailyQuiz;
        return isDailyQuiz ? 'daily-quiz' : 'quiz';
    }
    if (params.has('direqt_share')) return 'share';
    return 'none';
}

function getPromptDeeplink(): string | null {
    const params = new URLSearchParams(location.search);
    return params.get('direqt_prompt');
}

type ModalType = ReturnType<typeof getModalType>;

const checkEscape = (e: KeyboardEvent) => e.key === 'Escape' && closeModal();

function setModalParentStyling() {
    const { parent } = direqt_instance;
    parent.style.position = 'fixed';
    parent.style.inset = '0';
    parent.style.zIndex = '9999999128';
    parent.style.backgroundColor = '#000000b5';
    parent.style.cursor = 'pointer';
    parent.style.opacity = '1';
    parent.onclick = function () {
        closeModal();
    };
    window.addEventListener('keydown', checkEscape);
    document.querySelector('html')!.style.overflow = 'hidden';
    adjustParentZIndex(parent, 'increase');
}

function closeModal() {
    const { iframe, parent, layout } = direqt_instance;
    if (layout !== 'modal') {
        direqt_instance.layout = (parent.dataset.layout as Layout) || 'expand';
        iframe.style.opacity = '0'; //avoid ugliness as content shifts layout
        iframe.src = getSrc('none');
    }
    window.removeEventListener('keydown', checkEscape);
    document.querySelector('html')!.style.removeProperty('overflow');
    setDefaultStyling(layout !== 'modal');
}

function getInset(layout: Layout, bottomPadding: number, placement?: string) {
    if (layout !== 'overlay') return direqt_layouts[layout].inset || 'auto';
    const [top, right, btm, left] = direqt_layouts['overlay'].inset!.split(' ');
    const adjustedBottom = bottomPadding > 0 ? `${bottomPadding}px` : btm;
    const adjustedRight = placement === 'bottom-left' ? 'auto' : right;
    const adjustedLeft = placement === 'bottom-left' ? '36px' : left;
    return `${top} ${adjustedRight} ${adjustedBottom} ${adjustedLeft}`;
}

function setDefaultStyling(start = false) {
    const { layout, iframe, bottomPadding, placement, parent } =
        direqt_instance;

    parent.removeAttribute('style');
    parent.style.display = 'block';
    parent.style.width = '100%';
    parent.style.transition = 'opacity 0.3s ease-in-out';
    parent.onclick = null;

    if (layout === 'modal') parent.style.opacity = '0';

    iframe.frameBorder = '0';
    iframe.style.boxSizing = 'border-box';

    iframe.style.display = direqt_layouts[layout].display || 'initial';
    iframe.style.width = direqt_layouts[layout].width || '100%';
    iframe.style.maxWidth = direqt_layouts[layout].maxWidth || '100%';

    const fbHeight = start ? `${direqt_minHeight}px` : `${direqt_maxHeight}px`;
    const height = direqt_layouts[layout].height || fbHeight;
    //250px is for overlay and prevents text preview bubble from flashing on
    //first height adjustment
    iframe.style.height = start && layout === 'overlay' ? '250px' : height;

    iframe.style.borderRadius = direqt_layouts[layout].borderRadius || '7.5px';
    iframe.style.margin = direqt_layouts[layout].margin || '10px 0';
    iframe.style.position = direqt_layouts[layout].position || 'relative';
    iframe.style.inset = getInset(layout, bottomPadding, placement);
    iframe.style.zIndex = direqt_layouts[layout].zIndex || 'initial';
    iframe.style.lineHeight = direqt_layouts[layout].lineHeight || 'initial';
    iframe.style.transform = 'none';
    if (!start) return;
    iframe.style.opacity = '0';
}

function getSrc(isModal: ModalType = 'none'): string {
    const instance = direqt_instance;
    const { botId, storyId, disableUiExtensions } = instance;
    const encodedURL = encodeURIComponent(window.location.href);
    const url = new URL(`${direqt_domain}/chatbot/${botId}/embed`);
    for (const [key, value] of Object.entries({
        referrer: encodedURL,
        layout: instance.layout,
        storyId: storyId === 'auto' ? encodedURL : storyId,
        disableUiExtensions: String(disableUiExtensions),
        searchOnly: String(instance.searchOnly),
        color: instance.color,
        strict: instance.strict === 'false' ? '0' : '1',
        promptDeeplink: getPromptDeeplink(),
        startHint: isModal.includes('quiz') ? isModal : instance.startHint,
        placement: instance.placement,
    })) {
        if (value) url.searchParams.append(key, value);
    }
    instance.params = Object.fromEntries([...url.searchParams.entries()]);
    return url.toString();
}

function isJSON(str: string): boolean {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

/*
 *only include messages and their corresponding functions
 *handle logic within the functions themselves
 */
function handleMessage(event: MessageEvent) {
    if (event.origin !== direqt_domain || typeof event.data !== 'string')
        return;
    if (!isJSON(event.data)) return;
    const parsedEvent = JSON.parse(event.data);
    const {
        height,
        metadata: md,
        directive: parsedDir,
        testGroup,
        sid,
        botName,
    } = parsedEvent;
    if (!direqt_instance.testGroup && testGroup) {
        direqt_instance.testGroup = testGroup;
    }
    if (!direqt_instance.sid && sid) {
        direqt_instance.sid = sid;
    }
    const eventActions = {
        [`DIREQT_EXPAND`]: () => toggleHeight(true),
        [`DIREQT_SHRINK`]: () => toggleHeight(false),
        [`DIREQT_LOADED`]: () => loadSuccess(),
        [`DIREQT_INTERACTION`]: () => sendEvent('direqt_interaction', md),
        [`DIREQT_USERACTION`]: () => sendEvent('direqt_sentAction'),
        [`DIREQT_USERTEXT`]: () => sendEvent('direqt_sentText'),
        [`DIREQT_USERSUGGESTION`]: () => sendEvent('direqt_sentSuggestion'),
        [`DIREQT_FIRST`]: () => firstMessageHeightChange(height),
        [`DIREQT_INIT`]: () => sendEvent('direqt_init'),
        [`DIREQT_STORYERROR`]: () => removeDireqt('story', botName),
        [`DIREQT_BOTERROR`]: () => removeDireqt('bot'),
        [`DIREQT_PROMPTERROR`]: () => removeDireqt('prompt'),
        [`DIREQT_CLOSE`]: () => removeDireqt('conversation'),
        [`DIREQT_HIDE`]: () => removeDireqt('hide'),
        [`DIREQT_OVERLAYOPEN`]: () => toggleOverlay(true),
        [`DIREQT_OVERLAYCLOSE`]: () => toggleOverlay(false),
        [`DIREQT_OVERLAYHIDE`]: () => toggleOverlay(false, true),
        [`DIREQT_OVERLAYMSG`]: () => overlayBubbleOpen(height),
        [`DIREQT_FULLSCREEN`]: () => toggleFullscreen(true),
        [`DIREQT_EXITFULLSCREEN`]: () => toggleFullscreen(false),
        [`DIREQT_QUIZOPENMODAL`]: () => openModal('quiz'),
        [`DIREQT_QUIZOPENOVERLAY`]: () => toggleQuizOpenExpanded(),
        [`DIREQT_CLOSEMODAL`]: () => closeModal(),
        [`DIREQT_NAVIGATEHOSTTO`]: () => navigateHostTo(md),
    };
    if (direqt_local) console.log('[DIREQT EVENT]', parsedEvent);
    type Directives = keyof typeof eventActions;
    const directive = parsedDir as Directives;
    const action = eventActions[directive];
    if (action) action();
}

function navigateHostTo(md: string) {
    window.location.href = md;
}

function loadSuccess() {
    if (direqt_isConsole || direqt_instance.layout === 'modal') {
        opacityFadeIn(); //dont wait for first message in console or search modal
    }
    sendEvent('direqt_load_success');
}

function toggleFullscreen(toOpen: boolean) {
    const { iframe, parent } = direqt_instance;
    const visualViewport = window.visualViewport;
    const body = document.querySelector('body');
    if (!visualViewport || !body) return;
    let checkActive = false;
    const handleScrollToTop = () => {
        if (checkActive) return;
        checkActive = true;
        scrollToTop();
    };
    const scrollToTop = () => {
        //check if fullscreen has been closed
        if (parent.style.position !== 'fixed') {
            checkActive = false;
            return;
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        requestAnimationFrame(scrollToTop);
    };
    let offset = 0;
    const viewportHeight = window.innerHeight;
    //adjust iframe height to match visual viewport height important for soft
    //keyboard resizing on mobile
    function checkViewport(this: VisualViewport, event: Event) {
        if (parent.style.position !== 'fixed' || !event.target) {
            return;
        }
        handleScrollToTop();
        const vvHeight = (event.target as VisualViewport).height;
        iframe.style.height = `${vvHeight}px`;
        if (viewportHeight - vvHeight > 150) {
            const adjustment = viewportHeight - vvHeight - offset;
            parent.style.bottom = `${adjustment}px`;
            return;
        }
        if (viewportHeight === vvHeight || viewportHeight - vvHeight <= 150) {
            offset = viewportHeight - vvHeight;
            parent.style.bottom = '0px';
            return;
        }
    }

    if (toOpen) {
        //styles applied to both iframe and parent div
        const fullscreenStyles = {
            inset: '0',
            width: '100vw',
            borderRadius: '0',
            border: 'none',
            margin: '0',
            zIndex: '9999999128',
            filter: 'none',
        } as const;
        type FullscreenStyles = keyof typeof fullscreenStyles;
        const iframeStyle = iframe.style as CSSStyleDeclaration;
        const parentStyle = parent.style as CSSStyleDeclaration;
        Object.entries(fullscreenStyles).forEach(([key, value]) => {
            const typedKey = key as FullscreenStyles;
            iframeStyle[typedKey] = value;
            parentStyle[typedKey] = value;
        });

        //iframe exclusive styles
        iframeStyle.position = 'absolute';
        iframeStyle.height = '100dvh';

        //parent div exclusive styles
        parentStyle.position = 'fixed';
        parentStyle.height = '100lvh';
        parentStyle.backgroundColor = 'white';
        adjustParentZIndex(parent, 'increase');

        body.style.overflow = 'hidden';
        visualViewport.addEventListener('resize', checkViewport);
        return;
    }
    parent.style.backgroundColor = 'transparent'; //goes first to prevent flash of white
    body.style.overflow = 'auto';
    adjustParentZIndex(parent, 'reset');
    setDefaultStyling();
    iframe.scrollIntoView();
    visualViewport.removeEventListener('resize', checkViewport);
}

//adjusts z-index of parent elements to prevent iframe from being covered
function adjustParentZIndex(
    element: HTMLElement,
    action: 'increase' | 'reset'
) {
    let zIndex = 2147483647;
    while (
        element.parentElement &&
        !['BODY', 'HTML'].includes(element.parentElement.tagName)
    ) {
        if (action === 'increase') {
            element.parentElement.style.zIndex = (zIndex--).toString();
            element.parentElement.style.transform = 'none';
        } else if (action === 'reset') {
            element.parentElement.style.removeProperty('z-index');
            element.parentElement.style.removeProperty('transform');
        }
        element = element.parentElement;
    }
}

function removeDireqt(botError: Errors, botName?: string) {
    const { iframe, messageListener, storyId, parent } = direqt_instance;
    const botAtt = botName ? ` for ${botName}` : '';
    const errorTypes: {
        [key in Errors]: DireqtError;
    } = {
        bot: {
            error: 'Error Fetching Bot',
            details: 'invalid bot ID',
            logType: 'error',
            additionalInfo: 'botId',
        },
        noBot: {
            error: 'No Bot ID Provided',
            details: 'check data-bot-id',
            logType: 'error',
        },
        story: {
            error: 'Error Fetching Story',
            details: `invalid story ID${botAtt}`,
            logType: 'error',
            additionalInfo: 'storyId',
        },
        prompt: {
            error: 'No Smart Prompts Found',
            details: 'layout requires smart prompts. story ID:',
            logType: 'error',
            additionalInfo: 'storyId',
        },
        storyAuto: {
            error: 'Error Fetching Story',
            details: `no story${botAtt} associated with current URL`,
            logType: 'log',
        },
        conversation: {
            error: 'Conversation Closed',
            details: 'conversation closed by agent',
            logType: 'log',
        },
        hide: {
            error: 'Direqt Hidden',
            details: 'layout was set to hidden.',
            logType: 'log',
        },
    };
    let errIdx = botError;
    if (botError.includes('story') && storyId === 'auto') errIdx = 'storyAuto';
    if (botError === 'bot' && !direqt_instance.botId) errIdx = 'noBot';
    const { error, details, logType, additionalInfo } = errorTypes[errIdx];

    Object.assign(parent.style, { minHeight: '0' }); //for old <ins> elements using inline min-height
    closeModal(); //close modal if open
    parent.setAttribute('data-removed', '1');
    iframe.remove();
    direqt_instance.status = 'hidden';
    window.removeEventListener('message', messageListener);

    const info = additionalInfo ? direqt_instance[additionalInfo] : '';
    console[logType]('[DIREQT]', `${error}:\n${details} ${info}`);
    sendEvent('direqt_load_failure', error);
}

function opacityFadeIn() {
    const iframe = direqt_instance.iframe;
    iframe.style.transition = 'opacity 1s ease-in-out';
    iframe.style.opacity = '1';
    const transitionEndHandler = () => {
        iframe.style.transition = 'none';
        iframe.removeEventListener('transitionend', transitionEndHandler);
    };
    iframe.addEventListener('transitionend', transitionEndHandler);

    /* load status is set here rather than in loadSuccess
        to match when the iframe is actually shown to user */
    direqt_instance.status = 'loaded';
    window.dispatchEvent(new Event('direqt_loaded'));

    direqt_instance.observer.checkStatus();
}

/**********
 * Functions related to adjusting iframe height
 */

/**********
 * Adjusts iframe height within bounds of min/max height. If noTransition is
 * true, no transition will be applied.
 */
function adjustHeight(height: number, noTransition = false, force = false) {
    const { layout, iframe, status } = direqt_instance;
    if (direqt_layouts[layout].disableHeightChange && !force) return;
    if (height === 0) return;

    //check if height is within bounds
    if (height > direqt_maxHeight && layout !== 'overlay') {
        height = direqt_maxHeight;
    } else if (height < direqt_minHeight) height = direqt_minHeight;

    //only transition height change if iframe is loaded
    if (status === 'loaded' && !noTransition) {
        iframe.style.transition = 'height .5s ease-in-out';
    }

    iframe.style.height = height + 'px';

    //remove transition after height change
    if (noTransition) return;
    const transitionEndHandler = () => {
        iframe.style.transition = 'none';
        iframe.removeEventListener('transitionend', transitionEndHandler);
    };
    iframe.addEventListener('transitionend', transitionEndHandler);
}

function toggleHeight(expand: boolean) {
    const height = expand ? direqt_maxHeight : direqt_minHeight;
    adjustHeight(height);
}

function firstMessageHeightChange(height: number) {
    adjustHeight(height);
    opacityFadeIn();
    if (sessionStorage.getItem('overlayDismissed') == 'true') {
        toggleOverlay(false, true);
    }
}

function toggleOverlay(setOpen: boolean, hide?: boolean) {
    if (hide) {
        sessionStorage.setItem('overlayDismissed', 'true');
        direqt_instance.parent.style.display = 'none';
        return;
    }

    // If toggleOverlay is called without the hide flag,
    // we can assume that we want the iframe to be shown
    sessionStorage.setItem('overlayDismissed', 'false');
    direqt_instance.parent.style.display = 'block';

    const { iframe, bottomPadding } = direqt_instance;
    const iframeStyle = iframe.style;
    const overlayOpen = direqt_layouts.overlayOpen;
    const overlay = direqt_layouts.overlay;
    const shadow = 'drop-shadow(rgba(0, 0, 0, 0.25) 0px 1px 3px)';

    iframeStyle.bottom = bottomPadding ? `${bottomPadding}px` : '0px';
    iframeStyle.width = setOpen ? overlayOpen.width! : '108px';
    iframeStyle.filter = setOpen ? shadow : 'none';
    iframeStyle.willChange = 'filter'; // fixes drop shadow on Safari
    iframeStyle.borderRadius = bottomPadding ? '7.5px' : overlay.borderRadius!;

    const newHeight = parseInt(setOpen ? overlayOpen.height! : overlay.height!);
    adjustHeight(newHeight, true, true);
}

function openModal(modal: ModalType) {
    const maxHeight = modal === 'quiz' ? 400 : 600;
    const maxWidth = modal === 'quiz' ? 520 : 600;
    const iframe = direqt_instance.iframe;
    const parentWidth = iframe.ownerDocument.documentElement.clientWidth;
    const parentHeight = iframe.ownerDocument.documentElement.clientHeight;

    if (parentWidth >= maxWidth && parentHeight >= maxHeight) {
        iframe.style.width = `${maxWidth}px`;
        iframe.style.height = `${maxHeight}px`;
        iframe.style.bottom = '0px';
        return;
    }
    iframe.style.width = `${parentWidth}px`;
    iframe.style.height = `${parentHeight}px`;
}

function toggleQuizOpenExpanded() {
    const { iframe, parent } = direqt_instance;
    const realParentWidth = iframe.ownerDocument.documentElement.clientWidth;
    if (realParentWidth < 530) {
        iframe.style.bottom = '0px';
        iframe.style.width = realParentWidth + 'px';
        iframe.style.height = parent.clientHeight + 'px';
        return;
    }
    iframe.style.bottom = '30px';
    iframe.style.width = '520px';
    iframe.style.height = '460px';
    iframe.style.bottom = '0px';
    iframe.style.filter = 'drop-shadow(rgba(0, 0, 0, 0.25) 0px 1px 3px)';
    iframe.style.borderRadius = '7.5px 7.5px 0 0';
}

//Expands iframe height to show speech bubble preview
function overlayBubbleOpen(height: string) {
    const newHeight = parseInt(height) + 120; //120px is the height of the overlay bubble
    adjustHeight(newHeight, true);
}

/**********
 * Functions related to Analytics
 */

/**********
 * Session management
 * This object is used to manage session IDs for analytics events.
 * It uses localStorage if available, otherwise it falls back to object properties.
 * The session ID is updated after 30 minutes of inactivity.
 */
const DireqtSession = {
    fb_sid: null as string | null, // Fallback session ID if localStorage is not available
    fb_stime: null as number | null, // Fallback session time if localStorage is not available
    SESSION_TIMEOUT: 30 * 60 * 1000, // 30 minutes
    update() {
        const now = Date.now();
        try {
            const sLastEvent = localStorage.getItem('direqt_session_time');
            const lastEvent = sLastEvent ? parseInt(sLastEvent) : 0;
            const sessionID = localStorage.getItem('direqt_session_id');
            if (now - lastEvent > this.SESSION_TIMEOUT || !sessionID) {
                localStorage.setItem('direqt_session_id', generateUniqueId());
            }
            localStorage.setItem('direqt_session_time', now.toString());
        } catch (e) {
            // Fallback logic using object properties
            if (!this.fb_stime || now - this.fb_stime > this.SESSION_TIMEOUT) {
                this.fb_sid = generateUniqueId();
            }
            this.fb_stime = now;
        }
    },
    getSessionId() {
        try {
            return localStorage.getItem('direqt_session_id');
        } catch (e) {
            return this.fb_sid;
        }
    },
};

//checks for a specific debug parameter in the URL direqt_gtm_debug=1 to turn on
//gtag console debugger
function checkDebugParam(): boolean {
    const url = new URL(window.location.href);
    return url.searchParams.get('direqt_gtm_debug') === '1';
}

//all event names should follow the pattern direqt_<event_name>
function sendEvent(eventName: string, meta?: string, ce?: string) {
    if (!eventName.startsWith('direqt_')) return;
    DireqtSession.update();
    const { gtmDebug, gtm, collectAnalytics } = direqt_instance;
    const eventParams = {
        category: 'direqt',
        action: eventName.substring(eventName.indexOf('_') + 1),
        label: ce || 'chatbot',
        value: meta || 1,
    };
    if (gtmDebug) console.log('[DIREQT]', eventName, eventParams);
    if (direqt_isConsole) return;
    if (collectAnalytics) sendEventToDireqt(eventName, eventParams);
    if (gtm) sendEventToGoogleTags(eventName, eventParams);
}

function direqt_omitProps<T extends object, K extends keyof T>(
    obj: T,
    props: K[]
) {
    const result = { ...obj };
    props.forEach(prop => delete result[prop]);
    return result;
}

function sendEventToDireqt(eventType: string, eventData: EventParams) {
    /*********************************** */
    /* Set to true to test local events */
    const testLocalEvents = false;
    /******************************** */
    /******************************* */

    if (direqt_local && !testLocalEvents) return;
    const { referrer, storyId, disableUiExtensions } = direqt_instance.params;
    const { testGroup } = direqt_instance;
    const unpassables: (keyof DireqtInstance)[] = [
        'parent',
        'iframe',
        'messageListener',
        'observer',
    ];
    const passableInstance: InstanceVariables = direqt_omitProps(
        direqt_instance,
        unpassables
    );
    const data = {
        eventType,
        eventData: {
            ...eventData,
            value: eventData.value?.toString(), // value must be a string for BigQuery
        },
        location: window.location.href,
        session: DireqtSession.getSessionId(),
        testGroup,
        referrer: referrer && decodeURIComponent(referrer),
        storyId: storyId && decodeURIComponent(storyId),
        disableUiExtensions: disableUiExtensions,
        device: checkMobile() ? 'mobile' : 'desktop',
        timestamp: new Date().toISOString(),
        instanceData: { ...passableInstance },
    };
    if (direqt_local && testLocalEvents) {
        console.log('[DIREQT LOG]', data);
        return;
    }
    const payload = btoa(JSON.stringify(data));
    const collectUrl = `${direqt_domain}/api/collect`;

    if (navigator.sendBeacon) {
        navigator.sendBeacon(collectUrl, payload);
    } else {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', collectUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({ payload }));
    }
}

/**
 * Sends an event to Google Tags
 *
 * If the the global `gtag` function has been defined, we will use it.  If it
 * has not been defined, we push the event directly to the `dataLayer` array (if
 * it exists).
 *
 * References:
 *
 * - gtag: Global function for sending events to Google Analytics.
 *      Docs: https://developers.google.com/gtagjs
 * - dataLayer: Array used by Google Tag Manager to collect event data.
 *      Docs: https://developers.google.com/tag-manager/devguide
 */
function sendEventToGoogleTags(eventName: string, eventParams: EventParams) {
    if (typeof gtag === 'function') {
        gtag('event', eventName, eventParams);
    } else {
        dataLayer?.push({ event: eventName, ...eventParams });
    }
}

async function searchBarInit(direqt_el: HTMLElement) {
    const appHost = direqt_local ? '*' : 'https://chat.direqt.ai';

    const botId = direqt_el.getAttribute('data-bot-id');
    if (!botId) {
        console.error(
            '[DIREQT] Failed to initialize search. data-bot-id attribute not found'
        );
        return;
    }

    const bot = await fetchBot(botId);

    function sendChatMessage(message: string) {
        const iframe = direqt_el?.querySelector('iframe');
        iframe?.contentWindow!.postMessage(
            {
                cmd: 'direqt:sendMessage',
                message,
                context: JSON.stringify({
                    searchMessage: true,
                }),
            },
            appHost
        );
    }

    document
        .querySelectorAll('.direqt-search-input')
        ?.forEach((el: Element) => {
            const inputElement = el as HTMLInputElement;

            if (!inputElement.dataset.attached) {
                inputElement.addEventListener('keydown', e => {
                    if ((e as KeyboardEvent).key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        sendChatMessage(inputElement.value);
                        // dismiss the soft keyboard on mobile
                        inputElement.value = '';
                        (document.activeElement as HTMLElement)?.blur();
                        direqt_focusChat();
                    }
                });

                inputElement.placeholder = `Ask ${bot?.name || 'anything'}`;
                inputElement.dataset.attached = 'true';
            }
        });

    const mutationCallback = (mutationList: MutationRecord[]) => {
        mutationList.forEach(mutation => {
            if (mutation.type === 'childList') {
                const invokes = document.querySelectorAll(
                    '.direqt-search-invoke'
                );
                if (invokes.length === 0) return;

                const options = domainSpecificOptions[window.location.hostname];
                invokes.forEach(e => {
                    const newElement = document.createElement(
                        'direqt-search-trigger'
                    );

                    bot?.name && newElement.setAttribute('bot-name', bot.name);
                    bot?.color &&
                        newElement.setAttribute('accent-color', bot.color);

                    if (options?.searchTrigger?.extraStyles) {
                        newElement.setAttribute(
                            'style',
                            options.searchTrigger.extraStyles
                        );
                    }
                    e.replaceWith(newElement);
                });
            }
        });
    };

    const observer = new MutationObserver(mutationCallback);
    observer.observe(document.querySelector('body')!, {
        childList: true,
        subtree: true,
    });
}

// Should be shared with other components / webchat
async function fetchBot(botId: string): Promise<{
    _id: string;
    color: string;
    name: string;
    enableNewEmbedForWebchat?: boolean;
} | null> {
    if (!botId) return null;
    const path = `/api/chatbotProfile?botId=${botId}`;
    const request = new Request(`${direqt_domain}${path}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const resp = await fetch(request);

    return resp.json();
}

const initBetaEmbed = (botId?: string) => {
    console.log('[DIREQT]: Beta mode activating...');
    const betaBotId =
        botId ??
        document.cookie
            .split('; ')
            .find(r => r.startsWith('direqtBetaBotId='))
            ?.split('=')[1];

    const script = document.createElement('script');
    script.setAttribute('type', 'module');
    script.toggleAttribute('async');
    const embedUrl = direqt_local
        ? `http://localhost:5173/embed.js`
        : `https://embed.direqt.ai/embed.js`;
    if (betaBotId) {
        script.setAttribute('src', `${embedUrl}?id=${betaBotId}`);
    } else {
        script.setAttribute('src', embedUrl);
    }
    document.head.appendChild(script);
    console.log('[DIREQT]: Beta mode activated. Welcome to the future.');

    return true;
};

window['initDireqt'] = initDireqt; // publicly-documented
window['direqt'] = {
    createObserver,
    direqt_focusChat,
    direqt_local,
    initDireqt,
    searchBarInit,
    sendEvent,
    checkOrientationAndHideBot,
    getInstanceStatus: () => direqt_instance?.status,
    getLayout: () => direqt_instance?.layout,
    bot: null,
} as DireqtBrowserApi;

if (document.readyState === 'loading') {
    window.addEventListener(
        'DOMContentLoaded',
        async () => {
            await initBetaEmbed();
        },
        { once: true }
    );
} else {
    initBetaEmbed();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const direqt = window['direqt'] as DireqtBrowserApi;
